import { Button, Card, Text, Flex, Heading, Input, Label } from "theme-ui";
// @ts-ignore
import Modal from "react-modal";

import { useState, useEffect } from "react";
import { ErrorIcon } from "@brinks/common/Icons/Components/error";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import { ReSendIcon } from "@brinks/common/Icons/Components/resendIcon";
import { IS_AZURE_B2C_SCHEME, OTP_MAX_ATTEMPT } from "@brinks/common/utils/Config";
import { useUserWithLocations } from "../../Hooks/useUserWithLocations";
import { getCurrentUser } from "../../utils/secure-storage-utils";

interface Props {
  openOtpModal: Boolean;
  verifyOtp: (otp: any) => void;
  isVerifyOtpError: Boolean;
  otpErrorCount: number;
  isFetching: Boolean;
  isVerifyingOtp: Boolean;
  sendOtp: () => void;
}
const bg: any = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "420",
    height: "322",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    backgroundColor: "white",
    width: "45rem",
    maxWidth: "560px",
    padding: "30px",
    maxHeight: "100%",
    overflowY: "auto",
    position: "relative",
    borderRadius: "16px",
  },
};
export default function OtpModal({
  openOtpModal,
  isVerifyOtpError,
  otpErrorCount,
  verifyOtp,
  isFetching,
  isVerifyingOtp,
  sendOtp,
}: Props) {
  const [securityCode, setSecurityCode] = useState("");
  const [counter, setCounter] = useState(60);
  const { t } = useTranslation();
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Page_title: "User management | Add user",
        Action: "Add user verification Pop-up [SMS]",
      },
    });
  }, []);

  const resendCode = () => {
    setCounter(60);
    sendOtp();
  };

  useEffect(() => {
    const timer: any =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (openOtpModal) {
      setCounter(60)
    }
  }, [openOtpModal])

  //Azure B2C Integration starts here
  const {
    filteredUsers,
  } = useUserWithLocations({});
  const currentLoggedInUser = filteredUsers.find((user) => user.userId === getCurrentUser().id);
  //Azure B2C Integration ends here
  return (
    <>
      <Modal
        backdropOpacity={0.5}
        closeTimeoutMS={500}
        isOpen={openOtpModal}
        style={bg}
        transparent={true}
        center
      >
        <Card
          data-testid="OtpPage-card"
          sx={{
            width: ["100%"],
            px: ["16px", 0],
          }}
        >
          <Flex
            data-testid="OtpPage-card-wrapper"
            mt={25}
            sx={{
              flexDirection: "column",
            }}
          >
            <Heading
              data-testid="OtpPage-card-heading"
              sx={{
                fontWeight: "bold",
              }}
            >
              {t("AddNewUser.enter_verification")}
            </Heading>
            <Text
              data-testid="OtpPage-card-sms-text"
              mt={25}
              sx={{
                fontWeight: "weight_400",
              }}
            >
              {IS_AZURE_B2C_SCHEME && currentLoggedInUser && currentLoggedInUser.primaryPhone !== '' ? t("AddNewUser.call") : t("AddNewUser.sms")}
            </Text>
            <Label
              data-testid="OtpPage-card-label"
              mt={25}
              sx={{
                fontSize: "subText",
                color: "shade_800",
                fontWeight: "600",
              }}
            >
              {t("AddNewUser.code_lable")}
            </Label>
            <Input
              data-testid="OtpPage-card-input"
              sx={{
                p: "17px",
                border: "1px solid",
                borderColor: "borderColor",
                borderRadius: "6px",
              }}
              mt={25}
              type="number"
              value={securityCode}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  verifyOtp(securityCode);
                  setSecurityCode("");
                }
              }}
              autoFocus={true}
              onChange={(e) => {
                setSecurityCode(e.target.value);
              }}
              placeholder={t("AddNewUser.enter_code")}
              required
              disabled={otpErrorCount >= OTP_MAX_ATTEMPT}
            />
            {counter === 0 ? (
              <Flex
                onClick={() => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "Click_event",
                      Page_title: "Otp Modal | Enter verification code",
                      Action: "Resend verification code",
                    },
                  });
                  resendCode();
                }}
                sx={{ alignItems: "center", cursor: "pointer", pt: 20 }}
              >
                <ReSendIcon color={"primary"} />
                <Text
                  ml={2}
                  color="royalBlue_500"
                  sx={{
                    fontWeight: "weight_400",
                    lineHeight: "27.2px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  {t("Registration.resend_code")}
                </Text>
              </Flex>
            ) : (
              <Text
                data-testid="OtpPage-card-resend-code-flex-text"
                color="shade_400"
                sx={{ pt: 20 }}
              >
                {t("Registration.resend_code_in")} {counter}
              </Text>
            )}
            {isVerifyOtpError && (
              <Flex pt={2} data-testid="OtpPage-card-error-flex">
                <ErrorIcon
                  color={"raspberry_500"}
                  data-testid="OtpPage-card-error-icon"
                />
                <Text
                  data-testid="OtpPage-card-invalid-code"
                  pl={2}
                  color="raspberry_500"
                  sx={{
                    fontSize: "subText",
                    fontWeight: "weight_400",
                  }}
                >
                  {otpErrorCount < OTP_MAX_ATTEMPT ? t("AddNewUser.code_invalid") : t("AddNewUser.code_invalid_max_attempt")}
                </Text>
              </Flex>
            )}
            <Flex
              data-testid="OtpPage-card-resend-code-flex"
              mt={25}
              sx={{
                width: "100%",
              }}
            >
              <Button
                data-testid="OtpPage-card-resend-code-flex-button"
                onClick={() => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "Click_event",
                      Page_title: "User management | Add user",
                      Action: "Add user verification Pop-up [SMS] - Confirm",
                    },
                  });
                  verifyOtp(securityCode);
                  setSecurityCode("");
                }}
                variant={`${isFetching || isVerifyingOtp || otpErrorCount >= OTP_MAX_ATTEMPT ? "gray" : "primary"}`}
                sx={{
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                  height: 49,
                  width: ["100%"],
                  pointerEvents: `${isFetching || isVerifyingOtp || otpErrorCount >= OTP_MAX_ATTEMPT ? "none" : "auto"
                    }`,
                  mb: [20, 0],
                  borderRadius: "40px",
                  cursor: "pointer",
                }}
              >
                {t("confirm")}
              </Button>
            </Flex>
          </Flex>
        </Card>
      </Modal>
    </>
  );
}

export const get = (value: string | undefined, def?: string) =>
  value ? value : def;

export const REACT_APP_NATIVE_APP_CLIENT_ID = get(
  process.env["REACT_APP_NATIVE_APP_CLIENT_ID"],
  "0oa14kaktzQyTHaYw697"
);
// 0oa14kbrotTJrEUmr697
export const REACT_APP_NATIVE_APP_REDIRECT_URI = get(
  process.env["REACT_APP_NATIVE_APP_REDIRECT_URI"],
  "http://localhost:3000/callback"
);

export const REACT_APP_NATIVE_APP_BASE_URL = get(
  process.env["REACT_APP_NATIVE_APP_BASE_URL"],
  "https://blubeem-poc.okta.com"
);

export const REACT_APP_NATIVE_APP_DISCOVERY_URI = get(
  process.env["REACT_APP_NATIVE_APP_DISCOVERY_URI"],
  "https://blubeem-poc.okta.com/oauth2/aus14kec27B7ceemf697"
);

export const REACT_APP_WEB_GTM_ID = get(
  process.env["REACT_APP_WEB_GTM_ID"],
  "GTM-K6ZK2PB"
);

export const ENV = get(process.env["ENV"], "dev");

export const REACT_APP_WEB_THEME = get(
  process.env["REACT_APP_WEB_THEME"],
  "blubeem"
);

export const REACT_APP_NATIVE_APP_STAGE = get(
  process.env["REACT_APP_NATIVE_APP_STAGE"],
  "dev"
);

export const OTP_MAX_ATTEMPT = parseInt(
  get(process.env["OTP_MAX_ATTEMPT"], "5"),
  10
);

export const REACT_APP_API_KEY = get(
  process.env["REACT_APP_API_KEY"],
  "xcCwFBBftH6aZtPTCQEiR5vGe28vteKC8jaLPf7B"
);

export const REACT_APP_API_URL = get(
  process.env["REACT_APP_API_URL"],
  "https://api-tst.bac.brinks.com"
);

export const MSAL_SCOPE = `${process.env.REACT_APP_CLIENT_ID} offline_access openid`;

export const MSAL_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

export const MSAL_DOMAIN_NAME = process.env.REACT_APP_DOMAIN_NAME;

export const MSAL_DOMAIN_BLUBEEM_URL = process.env.REACT_APP_DOMAIN_BLUBEEM_URL;

export const MSAL_DOMAIN_BC_URL = process.env.REACT_APP_DOMAIN_BC_URL;

export const MSAL_BLUBEEM_POLICY_NAME = process.env.REACT_APP_BLUBEEM_POLICY_NAME;

export const MSAL_BC_POLICY_NAME = process.env.REACT_APP_BC_POLICY_NAME;

export const AUTHENTICATION_SCHEME = process.env.REACT_APP_AUTHENTICATION_SCHEME;

export const IS_AZURE_B2C_SCHEME = AUTHENTICATION_SCHEME?.toLowerCase().includes("azureb2c");

export const IS_BRINKS_THEME = REACT_APP_WEB_THEME?.toLowerCase().includes("brinks");
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Api, User } from "../api/Api";
import { IS_BRINKS_THEME } from "../utils/Config";

interface B2CUserSliceState {
  b2cErrMsg: any;
  id: string;
  users: User[];
  updateUser: any;
  b2cSuccess: boolean;
  b2cOtpSuccess: boolean;
  isError: boolean;
  isPwdRequestError: boolean,
  isDeleteUserError: boolean,
  isB2CFetching: boolean;
  isSaveB2CUserError: boolean;
  isUpdateB2CUserError: boolean;
  isB2cOtpError: boolean;
  isOTPSentError: boolean;
}

const initialState: B2CUserSliceState = {
  id: "",
  b2cErrMsg: "",
  users: [],
  b2cSuccess: false,
  b2cOtpSuccess: false,
  isError: false,
  isPwdRequestError: false,
  isDeleteUserError: false,
  updateUser: null,
  isB2CFetching: false,
  isSaveB2CUserError: false,
  isUpdateB2CUserError: false,
  isB2cOtpError: false,
  isOTPSentError: false
};

export const saveB2CUsers = createAsyncThunk<any, any, {}>(
  "addUsers",
  async (data, { rejectWithValue }) => {
    try {
      const res = await new Api().b2CUsers.createUsers(data);
      return res;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateB2CUser = createAsyncThunk<any, any, {}>(
  "updateUser",
  async (data, { rejectWithValue }) => {
    try {
      const res: any = await new Api().b2CUsers.editUsers(data.userId, data);
      getB2CUsers({});
      return res;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// password reset request by admin on behalf of other users
export const passwordResetRequest = createAsyncThunk<any, any, {}>(
  "passwordResetRequest",
  async (data, { rejectWithValue }) => {

    const appType = IS_BRINKS_THEME ? "Brinks" : "Blubeem"
    const params = {
      headers: {
        "app-type": `${appType}`
      },
    };

    try {
      const res: any = await new Api().sendEmail.passwordResetRequest(
        data.userId,
        data,
        params
      );
      return res;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// send OTP
export const sendOtpSMS = createAsyncThunk<any, any, {}>(
  "sendOtp",
  async (data, { rejectWithValue }) => {
    try {
      const res: any = await new Api().b2CUsers.sendOtp(data);
      return res;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Verify OTP
export const verifyOtpSMS = createAsyncThunk<any, any, {}>(
  "verifyOtp",
  async (data, { rejectWithValue }) => {
    const ssid = localStorage.getItem("service-sid");
    const token = localStorage.getItem("mfa-token");

    const params = {
      headers: {
        "mfa-token": `${token}`,
        "service-sid": `${ssid}`,
      },
    };

    try {
      // update the code here to call verifyOtp endpoint.
      const res: any = await new Api().b2CUsers.verifyOtp(data, params);
      return res;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteB2CUser = createAsyncThunk<any, any, {}>(
  "deleteUser",
  async (data, { rejectWithValue }) => {
    try {
      const res: any = await new Api().b2CUsers.deleteUser(data.userId);
      return res;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const activateUser = createAsyncThunk<any, any, {}>(
  "activateUser",
  async (data, { rejectWithValue }) => {
    try {
      const res: any = await new Api().b2CUsers.activateAccount(
        data.userId,
      );
      return res;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getB2CUsers = createAsyncThunk<any, any, {}>("user", () => {
  const res = new Api().b2CUsers.getAllusers();
  return res;
});

export const getUserInfo = createAsyncThunk<any, any, {}>(
  "userInfo",
  async (data, { rejectWithValue }) => {
    try {
      const res: any = await new Api().b2CUsers.getuserInfo(data.currentUserId);
      return res;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const b2cUserSlice = createSlice({
  name: "b2cUserSlice",
  initialState,
  reducers: {
    closeB2CMessage: () => initialState,
    resetB2CState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveB2CUsers.pending, (state) => {
        state.isB2CFetching = true;
        state.b2cSuccess = false;
      })
      .addCase(saveB2CUsers.fulfilled, (state, { payload }) => {
        state.isB2CFetching = false;
        state.b2cSuccess = payload.status === 200 ? true : false;
      })
      .addCase(saveB2CUsers.rejected, (state, { payload }) => {
        state.b2cErrMsg = payload;
        state.isB2CFetching = false;
        state.isSaveB2CUserError = true;
      })
      .addCase(getB2CUsers.pending, (state) => {
        state.b2cSuccess = false;
        state.isB2CFetching = true;
      })
      .addCase(getB2CUsers.fulfilled, (state, { payload }) => {
        state.isB2CFetching = false;
        state.users = payload.data.users;
      })
      .addCase(getB2CUsers.rejected, (state, { payload }) => {
        state.isError = true;
        state.b2cSuccess = false;
        state.isB2CFetching = false;
      })
      .addCase(updateB2CUser.pending, (state) => {
        state.b2cSuccess = false;
        state.isB2CFetching = true;
      })
      .addCase(updateB2CUser.fulfilled, (state, { payload }) => {
        state.updateUser = payload.data;
        state.isB2CFetching = false;
        state.b2cSuccess = true;
      })
      .addCase(updateB2CUser.rejected, (state, { payload }) => {
        state.b2cSuccess = false;
        state.b2cErrMsg = payload;
        state.isB2CFetching = false;
        state.isUpdateB2CUserError = true;
      })
      .addCase(passwordResetRequest.rejected, (state, { payload }: any) => {
        state.isPwdRequestError = true;
        state.b2cErrMsg = payload
      })
      .addCase(deleteB2CUser.rejected, (state, { payload }: any) => {
        state.isDeleteUserError = true;
        state.b2cErrMsg = payload
      })
      .addCase(sendOtpSMS.rejected, (state, { payload }: any) => {
        state.isOTPSentError = true;
        state.b2cErrMsg = payload
      })
  },
});

export const { closeB2CMessage, resetB2CState } = b2cUserSlice.actions;
export default b2cUserSlice.reducer;
